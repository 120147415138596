buttons:
  pureAccountSettings: 帳戶設定
  pureLoginOut: 離開系統
  pureLogin: 登入
  pureOpenSystemSet: 開啟系統設定
  pureReload: 重新載入
  pureCloseCurrentTab: 關閉當前標籤頁
  pureCloseLeftTabs: 關閉左側標籤頁
  pureCloseRightTabs: 關閉右側標籤頁
  pureCloseOtherTabs: 關閉其他標籤頁
  pureCloseAllTabs: 關閉所有標籤頁
  pureContentFullScreen: 內容區全螢幕
  pureContentExitFullScreen: 內容區退出全螢幕
  pureClickCollapse: 點擊摺疊
  pureClickExpand: 點擊展開
  pureConfirm: 確認
  pureSwitch: 切換
  pureClose: 關閉
  pureBackTop: 回到頂部
  pureOpenText: 開
  pureCloseText: 關
search:
  pureTotal: 共
  pureHistory: 搜尋歷史
  pureCollect: 收藏
  pureDragSort: （可拖曳排序）
  pureEmpty: 暫無搜尋結果
  purePlaceholder: 搜尋選單
panel:
  pureSystemSet: 系統設定
  pureCloseSystemSet: 關閉設定
  pureClearCacheAndToLogin: 清除緩存並返回登入頁
  pureClearCache: 清除緩存
  pureOverallStyle: 整體風格
  pureOverallStyleLight: 淺色
  pureOverallStyleLightTip: 清新起航，點亮舒適的工作介面
  pureOverallStyleDark: 深色
  pureOverallStyleDarkTip: 月光序曲，沉醉於夜的靜謐雅緻
  pureOverallStyleSystem: 自動
  pureOverallStyleSystemTip: 同步時光，介面隨晨昏自然呼應
  pureThemeColor: 主題色
  pureLayoutModel: 導航模式
  pureVerticalTip: 左側菜單，親切熟悉
  pureHorizontalTip: 頂部菜單，簡潔概覽
  pureMixTip: 混合菜單，靈活多變
  pureDoubleTip: 雙欄菜單，快速定位
  pureStretch: 頁寬
  pureStretchFixed: 固定
  pureStretchFixedTip: 緊湊頁面，輕鬆找到所需資訊
  pureStretchCustom: 自訂
  pureStretchCustomTip: 最小1280、最大1600
  pureTagsStyle: 標籤風格
  pureTagsStyleSmart: 靈動
  pureTagsStyleSmartTip: 靈動標籤，添趣生輝
  pureTagsStyleCard: 卡片
  pureTagsStyleCardTip: 卡片標籤，高效瀏覽
  pureTagsStyleChrome: Google
  pureTagsStyleChromeTip: Google風格，經典美觀
  pureInterfaceDisplay: 介面顯示
  pureGreyModel: 灰色模式
  pureWeakModel: 色弱模式
  pureHiddenTags: 隱藏標籤頁
  pureHiddenFooter: 隱藏頁腳
  pureMultiTagsCache: 標籤持久化
menus:
  pureHome: 首頁
  pureLogin: 登入
  pureEmpty: 無Layout頁
  pureTable: 表格
  pureTenantManagement: 租戶管理
  pureTenantList: 租戶列表
  pureTenantPackage: 租戶套餐
  pureSysManagement: 系統管理
  pureUser: 使用者管理
  pureRole: 角色管理
  pureSystemMenu: 菜單管理
  pureDept: 部門管理
  pureDict: 字典管理
  pureSysMonitor: 系統監控
  pureOnlineUser: 在線使用者
  pureLoginLog: 登入紀錄
  pureOperationLog: 操作紀錄
  pureSystemLog: 系統紀錄
  pureEditor: 編輯器
  pureAbnormal: 異常頁面
  pureFourZeroFour: "404"
  pureFourZeroOne: "403"
  pureFive: "500"
  pureComponents: 組件
  pureDialog: 函數式彈窗
  pureMessage: 訊息提示
  pureVideo: 影片
  pureSegmented: 分段控制器
  pureWaterfall: 瀑布流無限滾動
  pureMap: 地圖
  pureDraggable: 拖曳
  pureSplitPane: 切割面板
  pureText: 文本省略
  pureElButton: 按鈕
  pureCheckButton: 可選按鈕
  pureButton: 按鈕動效
  pureCropping: 圖片裁剪
  pureAnimatecss: animate.css選擇器
  pureCountTo: 數字動畫
  pureSelector: 範圍選擇器
  pureFlowChart: 流程圖
  pureSeamless: 無縫滾動
  pureContextmenu: 右鍵選單
  pureTypeit: 打字機
  pureJsonEditor: JSON編輯器
  pureColorPicker: 顏色選擇器
  pureDatePicker: 日期選擇器
  pureDateTimePicker: 日期時間選擇器
  pureTimePicker: 時間選擇器
  pureTag: 標籤
  pureStatistic: 統計組件
  pureCollapse: 摺疊面板
  pureGanttastic: 甘特圖
  pureProgress: 進度條
  pureUpload: 檔案上傳
  pureCheckCard: 多選卡片
  pureMenus: 多級菜單
  pureMenu1: 菜單1
  pureMenu1-1: 菜單1-1
  pureMenu1-2: 菜單1-2
  pureMenu1-2-1: 菜單1-2-1
  pureMenu1-2-2: 菜單1-2-2
  pureMenu1-3: 菜單1-3
  pureMenu2: 菜單二
  purePermission: 權限管理
  purePermissionPage: 頁面權限
  purePermissionButton: 按鈕權限
  purePermissionButtonRouter: 路由返回按鈕權限
  purePermissionButtonLogin: 登入接口返回按鈕權限
  pureTabs: 標籤頁操作
  pureGuide: 引導頁
  pureAble: 功能
  pureMenuTree: 菜單樹結構
  pureVideoFrame: 影片幀截取-wasm版
  pureWavesurfer: 音頻視覺化
  pureRipple: 波紋 (Ripple)
  pureMqtt: MQTT客戶端 (mqtt)
  pureOptimize: 防抖、截流、複製、長按指令
  pureVerify: 圖形驗證碼
  pureWatermark: 水印
  purePrint: 列印
  pureDownload: 下載
  pureExternalPage: 外部頁面
  pureExternalDoc: 文檔外鏈
  pureEmbeddedDoc: 文檔內嵌
  pureExternalLink: vue-pure-admin
  pureUtilsLink: pure-admin-utils
  pureColorHuntDoc: 調色盤
  pureUiGradients: 渐變色
  pureEpDoc: element-plus
  pureTailwindcssDoc: tailwindcss
  pureVueDoc: vue3
  pureViteDoc: vite
  purePiniaDoc: pinia
  pureRouterDoc: vue-router
  pureAbout: 關於
  pureResult: 結果頁面
  pureSuccess: 成功頁面
  pureFail: 失敗頁面
  pureIconSelect: 圖示選擇器
  pureTimeline: 時間軸
  pureLineTree: 樹形連接線
  pureList: 列表頁面
  pureCardList: 卡片列表頁
  pureDebounce: 防抖節流
  pureFormDesign: 表單設計器
  pureBarcode: 條形碼
  pureQrcode: 二維碼
  pureCascader: 區域級聯選擇器
  pureSwiper: Swiper插件
  pureVirtualList: 虛擬列表
  purePdf: PDF預覽
  pureExcel: 匯出Excel
  pureInfiniteScroll: 表格無限滾動
  pureSensitive: 敏感詞過濾
  purePinyin: 漢語拼音
  pureDanmaku: 弹幕
  pureSchemaForm: 表單
  pureTableBase: 基礎用法
  pureTableHigh: 高級用法
  pureTableEdit: 可編輯用法
  pureVxeTable: 虛擬滾動
  pureBoard: 藝術畫板
  pureMindMap: 思維導圖
  pureMenuOverflow: 目錄超出顯示 Tooltip 文字提示
  pureChildMenuOverflow: 菜單超出顯示 Tooltip 文字提示
status:
  pureLoad: 載入中...
  pureMessage: 訊息
  pureNotify: 通知
  pureTodo: 待辦
  pureNoMessage: 暫無訊息
  pureNoNotify: 暫無通知
  pureNoTodo: 暫無待辦
login:
  pureTenant: 租戶名
  pureUsername: 帳號
  purePassword: 密碼
  pureVerifyCode: 驗證碼
  pureRemember: 天內免登入
  pureRememberInfo: 勾選並登入後，規定天數內無需輸入使用者名和密碼會自動進入系統
  pureSure: 確認密碼
  pureForget: 忘記密碼?
  pureLogin: 登入
  pureThirdLogin: 第三方登入
  purePhoneLogin: 手機登入
  pureQRCodeLogin: 二維碼登入
  pureRegister: 註冊
  pureWeChatLogin: 微信登入
  pureAlipayLogin: 支付寶登入
  pureQQLogin: QQ登入
  pureWeiBoLogin: 微博登入
  purePhone: 手機號碼
  pureSmsVerifyCode: 短信驗證碼
  pureBack: 返回
  pureTest: 模擬測試
  pureTip: 掃碼後點擊「確認」，即可完成登入
  pureDefinite: 確定
  pureLoginSuccess: 登入成功
  pureLoginFail: 登入失敗
  pureRegisterSuccess: 註冊成功
  pureTickPrivacy: 請勾選隱私政策
  pureReadAccept: 我已仔細閱讀並接受
  purePrivacyPolicy: 《隱私政策》
  pureGetVerifyCode: 獲取驗證碼
  pureInfo: 秒後重新獲取
  pureTenantReg: 請輸入租戶名
  pureUsernameReg: 請輸入帳號
  purePassWordReg: 請輸入密碼
  pureVerifyCodeReg: 請輸入驗證碼
  pureVerifyCodeCorrectReg: 請輸入正確的驗證碼
  pureVerifyCodeSixReg: 請輸入6位數字驗證碼
  purePhoneReg: 請輸入手機號碼
  purePhoneCorrectReg: 請輸入正確的手機號碼格式
  purePassWordRuleReg: 密碼格式應為8-18位數字、字母、符號的任意兩種組合
  purePassWordSureReg: 請輸入確認密碼
  purePassWordDifferentReg: 兩次密碼不一致！
  purePassWordUpdateReg: 修改密碼成功