buttons:
  pureAccountSettings: アカウント設定
  pureLoginOut: システムからログアウト
  pureLogin: ログイン
  pureOpenSystemSet: システム設定を開く
  pureReload: 再読み込み
  pureCloseCurrentTab: 現在のタブを閉じる
  pureCloseLeftTabs: 左のタブを閉じる
  pureCloseRightTabs: 右のタブを閉じる 
  pureCloseOtherTabs: 他のタブを閉じる
  pureCloseAllTabs: 全てのタブを閉じる
  pureContentFullScreen: コンテンツフルスクリーン
  pureContentExitFullScreen: コンテンツフルスクリーン解除
  pureClickCollapse: 折りたたむ
  pureClickExpand: 展開する
  pureConfirm: 確認
  pureSwitch: 切替え
  pureClose: 閉じる
  pureBackTop: トップに戻る
  pureOpenText: 開
  pureCloseText: 閉
search:
  pureTotal: 合計
  pureHistory: 検索履歴
  pureCollect: お気に入り
  pureDragSort: (ドラッグ＆ドロップでソート可能)
  pureEmpty: 検索結果なし
  purePlaceholder: メニュー検索
panel:
  pureSystemSet: システム設定
  pureCloseSystemSet: 設定を閉じる
  pureClearCacheAndToLogin: キャッシュをクリアしてログインページへ戻る
  pureClearCache: キャッシュをクリア
  pureOverallStyle: 全体スタイル
  pureOverallStyleLight: ライトカラー
  pureOverallStyleLightTip: 新鮮なスタートで、快適な作業環境を照らす
  pureOverallStyleDark: ダークカラー
  pureOverallStyleDarkTip: 月光の前奏曲、夜の静けさに浸る
  pureOverallStyleSystem: 自動
  pureOverallStyleSystemTip: 時間と共に同期し、朝夕の自然な変化に合わせてインターフェースが変わる
  pureThemeColor: テーマカラー
  pureLayoutModel: ナビゲーションモード
  pureVerticalTip: 左側メニュー、親しみやすい
  pureHorizontalTip: トップメニュー、シンプルな概観
  pureMixTip: ミックスメニュー、柔軟で多様
  pureDoubleTip: ダブルカラムメニュー、迅速な位置確認
  pureStretch: ページ幅
  pureStretchFixed: 固定
  pureStretchFixedTip: コンパクトなページで必要な情報を簡単に見つける
  pureStretchCustom: カスタム
  pureStretchCustomTip: 最小1280、最大1600
  pureTagsStyle: タブスタイル
  pureTagsStyleSmart: ダイナミック
  pureTagsStyleSmartTip: ダイナミックなタブで楽しさをプラス
  pureTagsStyleCard: カード
  pureTagsStyleCardTip: カードスタイルのタブで効率的なブラウジング
  pureTagsStyleChrome: クロム
  pureTagsStyleChromeTip: クロムスタイル、クラシックで美しい
  pureInterfaceDisplay: インターフェース表示
  pureGreyModel: グレーモード
  pureWeakModel: 色弱モード
  pureHiddenTags: タブを隠す
  pureHiddenFooter: フッターを隠す
  pureMultiTagsCache: タブの永続化
menus:
  pureHome: ホーム
  pureLogin: ログイン
  pureEmpty: Layoutページなし
  pureTable: テーブル
  pureTenantManagement: テナント管理
  pureTenantList: テナント一覧
  pureTenantPackage: テナントパッケージ
  pureSysManagement: システム管理
  pureUser: ユーザー管理
  pureRole: ロール管理
  pureSystemMenu: メニュー管理
  pureDept: 部門管理
  pureDict: 辞書管理
  pureSysMonitor: システムモニタリング
  pureOnlineUser: オンラインユーザー
  pureLoginLog: ログインログ
  pureOperationLog: 操作ログ
  pureSystemLog: システムログ
  pureEditor: エディター
  pureAbnormal: 異常ページ
  pureFourZeroFour: "404"
  pureFourZeroOne: "403"
  pureFive: "500"
  pureComponents: コンポーネント
  pureDialog: 関数型ポップアップ
  pureMessage: メッセージ通知
  pureVideo: ビデオ
  pureSegmented: セグメントコントローラー
  pureWaterfall: ウォーターフォール無限スクロール
  pureMap: 地図
  pureDraggable: ドラッグ可能
  pureSplitPane: 分割パネル
  pureText: テキスト省略
  pureElButton: ボタン
  pureCheckButton: チェックボタン
  pureButton: ボタンアニメーション
  pureCropping: 画像クロッピング
  pureAnimatecss: animate.cssセレクタ
  pureCountTo: 数値アニメーション
  pureSelector: 範囲セレクタ
  pureFlowChart: フローチャート
  pureSeamless: 途切れのないスクロール
  pureContextmenu: 右クリックメニュー
  pureTypeit: タイプライター
  pureJsonEditor: JSONエディター
  pureColorPicker: カラーピッカー
  pureDatePicker: 日付セレクター
  pureDateTimePicker: 日時セレクター
  pureTimePicker: 時間セレクター
  pureTag: タグ
  pureStatistic: 統計コンポーネント
  pureCollapse: 折りたたみパネル
  pureGanttastic: ガントチャート
  pureProgress: プログレスバー
  pureUpload: ファイルアップロード
  pureCheckCard: チェック可能なカード
  pureMenus: マルチレベルメニュー
  pureMenu1: メニュー1
  pureMenu1-1: メニュー1-1
  pureMenu1-2: メニュー1-2
  pureMenu1-2-1: メニュー1-2-1
  pureMenu1-2-2: メニュー1-2-2
  pureMenu1-3: メニュー1-3
  pureMenu2: メニュー2
  purePermission: 権限管理
  purePermissionPage: ページ権限
  purePermissionButton: ボタン権限
  purePermissionButtonRouter: ルートリターンボタン権限
  purePermissionButtonLogin: ログインインターフェースの戻るボタン権限
  pureTabs: タブ操作
  pureGuide: ガイドページ
  pureAble: 機能
  pureMenuTree: メニューのツリー構造
  pureVideoFrame: ビデオフレームの抽出（wasm版）
  pureWavesurfer: 音声の可視化
  pureRipple: 波紋（Ripple）
  pureMqtt: MQTTクライアント（mqtt）
  pureOptimize: デバウンス、スロットリング、コピー、ロングプレスディレクティブ
  pureVerify: グラフィック認証コード
  pureWatermark: ウォーターマーク
  purePrint: 印刷
  pureDownload: ダウンロード
  pureExternalPage: 外部ページ
  pureExternalDoc: ドキュメント外部リンク
  pureEmbeddedDoc: ドキュメント埋め込み
  pureExternalLink: vue-pure-admin
  pureUtilsLink: pure-admin-utils
  pureColorHuntDoc: カラーパレット
  pureUiGradients: グラデーション
  pureEpDoc: element-plus
  pureTailwindcssDoc: tailwindcss
  pureVueDoc: vue3
  pureViteDoc: vite
  purePiniaDoc: pinia
  pureRouterDoc: vue-router
  pureAbout: について
  pureResult: 結果ページ
  pureSuccess: 成功ページ
  pureFail: 失敗ページ
  pureIconSelect: アイコンセレクター
  pureTimeline: タイムライン
  pureLineTree: 木形状接続線
  pureList: リストページ
  pureCardList: カードリストページ
  pureDebounce: デバウンス・スロットリング
  pureFormDesign: フォームデザイナー
  pureBarcode: バーコード
  pureQrcode: QRコード
  pureCascader: 地域カスケードセレクター
  pureSwiper: Swiperプラグイン
  pureVirtualList: バーチャルリスト
  purePdf: PDFプレビュー
  pureExcel: Excelエクスポート
  pureInfiniteScroll: 無限スクロールテーブル
  pureSensitive: 感受性語フィルター
  purePinyin: 中国語ピンイン
  pureDanmaku: 弾幕
  pureSchemaForm: フォーム
  pureTableBase: 基本的な使い方
  pureTableHigh: 高度な使い方
  pureTableEdit: 編集可能な使い方
  pureVxeTable: バーチャルスクロール
  pureBoard: アートボード
  pureMindMap: マインドマップ
  pureMenuOverflow: ディレクトリが超過した際のTooltipテキスト提示
  pureChildMenuOverflow: メニューが超過した際のTooltipテキスト提示
status:
  pureLoad: 読み込み中...
  pureMessage: メッセージ
  pureNotify: 通知
  pureTodo: 未処理
  pureNoMessage: メッセージはありません
  pureNoNotify: 通知はありません
  pureNoTodo: 未処理の事項はありません
login:
  pureTenant: テナント名
  pureUsername: アカウント
  purePassword: パスワード
  pureVerifyCode: 認証コード
  pureRemember: ログインを保持する日数
  pureRememberInfo: チェックしてログインすると、指定された日数内にユーザー名とパスワードを入力せずに自動的にシステムにログインします
  pureSure: パスワードを確認
  pureForget: パスワードを忘れましたか?
  pureLogin: ログイン
  pureThirdLogin: 第三者ログイン
  purePhoneLogin: 携帯電話でログイン
  pureQRCodeLogin: QRコードでログイン
  pureRegister: 登録
  pureWeChatLogin: WeChatでログイン
  pureAlipayLogin: 支払い宝でログイン
  pureQQLogin: QQでログイン
  pureWeiBoLogin: 微博でログイン
  purePhone: 携師番号
  pureSmsVerifyCode: SMS認証コード
  pureBack: 戻る
  pureTest: 模擬テスト
  pureTip: 「確認」をクリックしてスキャンすると、ログインが完了します
  pureDefinite: 確定
  pureLoginSuccess: ログイン成功
  pureLoginFail: ログイン失敗
  pureRegisterSuccess: 登録成功
  pureTickPrivacy: プライバシーポリシーにチェックしてください
  pureReadAccept: 私は注意深く読んで同意しました
  purePrivacyPolicy: 《プライバシーポリシー》
  pureGetVerifyCode: 認証コードを取得
  pureInfo: 秒後に再取得
  pureTenantReg: テナント名を入力してください
  pureUsernameReg: アカウントを入力してください
  purePassWordReg: パスワードを入力してください
  pureVerifyCodeReg: 認証コードを入力してください
  pureVerifyCodeCorrectReg: 正しい認証コードを入力してください
  pureVerifyCodeSixReg: 6桁の数字の認証コードを入力してください
  purePhoneReg: 携帯番号を入力してください
  purePhoneCorrectReg: 正しい携帯番号の形式を入力してください
  purePassWordRuleReg: パスワードは8～18文字の数字、アルファベット、記号の任意の二種類の組み合わせです
  purePassWordSureReg: 確認のためにもう一度パスワードを入力してください
  purePassWordDifferentReg: パスワードが一致しません！
  purePassWordUpdateReg: パスワードの変更に成功しました