buttons:
  pureAccountSettings: 계정 설정
  pureLoginOut: 시스템 로그아웃
  pureLogin: 로그인
  pureOpenSystemSet: 시스템 설정 열기
  pureReload: 새로고침
  pureCloseCurrentTab: 현재 탭 닫기
  pureCloseLeftTabs: 왼쪽 탭 닫기
  pureCloseRightTabs: 오른쪽 탭 닫기
  pureCloseOtherTabs: 기타 탭 닫기
  pureCloseAllTabs: 모든 탭 닫기
  pureContentFullScreen: 내용 영역 전체 화면
  pureContentExitFullScreen: 내용 영역 전체 화면 해제
  pureClickCollapse: 클릭하여 접기
  pureClickExpand: 클릭하여 펴기
  pureConfirm: 확인
  pureSwitch: 전환
  pureClose: 닫기
  pureBackTop: 맨 위로 가기
  pureOpenText: 켜기
  pureCloseText: 끄기
search:
  pureTotal: 총
  pureHistory: 검색 기록
  pureCollect: 즐겨찾기
  pureDragSort: (드래그하여 정렬 가능)
  pureEmpty: 검색 결과 없음
  purePlaceholder: 메뉴 검색
panel:
  pureSystemSet: 시스템 설정
  pureCloseSystemSet: 설정 닫기
  pureClearCacheAndToLogin: 캐시 지우고 로그인 페이지로 돌아가기
  pureClearCache: 캐시 지우기
  pureOverallStyle: 전체 스타일
  pureOverallStyleLight: 밝은색
  pureOverallStyleLightTip: 깨끗한 출발, 편안한 작업 인터페이스를 밝히세요
  pureOverallStyleDark: 어두운색
  pureOverallStyleDarkTip: 달빛 서곡, 밤의 조용한 우아함에 빠져들다
  pureOverallStyleSystem: 자동
  pureOverallStyleSystemTip: 시간과 동기화, 인터페이스가 새벽과 황혼에 자연스럽게 반응합니다.
  pureThemeColor: 테마 색상
  pureLayoutModel: 내비게이션 모드
  pureVerticalTip: 좌측 메뉴, 친근하고 익숙함
  pureHorizontalTip: 상단 메뉴, 간결한 개요
  pureMixTip: 혼합 메뉴, 유연하고 다양함
  pureDoubleTip: 더블 컬럼 메뉴, 빠른 위치 확인
  pureStretch: 페이지 폭
  pureStretchFixed: 고정
  pureStretchFixedTip: 컴팩트 페이지, 필요한 정보 쉽게 찾기
  pureStretchCustom: 사용자 정의
  pureStretchCustomTip: 최소 1280, 최대 1600
  pureTagsStyle: 탭 스타일
  pureTagsStyleSmart: 민첩한
  pureTagsStyleSmartTip: 민첩한 탭, 재미있고 효과적
  pureTagsStyleCard: 카드
  pureTagsStyleCardTip: 카드 탭, 효율적인 브라우징
  pureTagsStyleChrome: 구글
  pureTagsStyleChromeTip: 구글 스타일, 클래식하고 아름담
  pureInterfaceDisplay: 인터페이스 표시
  pureGreyModel: 그레이 모드
  pureWeakModel: 색약 모드
  pureHiddenTags: 탭 숨기기
  pureHiddenFooter: 푸터 숨기기
  pureMultiTagsCache: 탭 지속성
menus:
  pureHome: 홈페이지
  pureLogin: 로그인
  pureEmpty: 레이아웃 없음
  pureTable: 표
  pureTenantManagement: 임차인 관리
  pureTenantList: 임차인 목록
  pureTenantPackage: 테넌트 패키지
  pureSysManagement: 시스팅 관리
  pureUser: 사용자 관리
  pureRole: 역할 관리
  pureSystemMenu: 메뉴 관리
  pureDept: 부서 관리
  pureDict: 사전 관리
  pureSysMonitor: 시스템 모니터링
  pureOnlineUser: 온라인 사용자
  pureLoginLog: 로그인 로그
  pureOperationLog: 작업 로그
  pureSystemLog: 시스템 로그
  pureEditor: 편집기
  pureAbnormal: 예외 페이지
  pureFourZeroFour: "404"
  pureFourZeroOne: "403"
  pureFive: "500"
  pureComponents: 컴포넌트
  pureDialog: 함수형 팝업
  pureMessage: 메시지 팁
  pureVideo: 비디오
  pureSegmented: 세그먼트 컨트롤러
  pureWaterfall: 물방울 흐름 무한 스크롤
  pureMap: 지도
  pureDraggable: 드래그
  pureSplitPane: 분할 패널
  pureText: 텍스트 줄임
  pureElButton: 버튼
  pureCheckButton: 선택 버튼
  pureButton: 버튼 효과
  pureCropping: 이미지 크롭
  pureAnimatecss: animate.css 선택
  pureCountTo: 숫자 애니메이션
  pureSelector: 범위 선택기
  pureFlowChart: 흐름도
  pureSeamless: 무한 스크롤
  pureContextmenu: 우클릭 메뉴
  pureTypeit: 타이핑 효과
  pureJsonEditor: JSON 편집기
  pureColorPicker: 색상 선택기
  pureDatePicker: 날짜 선택기
  pureDateTimePicker: 날짜 시간 선택기
  pureTimePicker: 시간 선택기
  pureTag: 태그
  pureStatistic: 통계 컴포넌트
  pureCollapse: 접기 패널
  pureGanttastic: 간트 차트
  pureProgress: 진행바
  pureUpload: 파일 업로드
  pureCheckCard: 체크 카드
  pureMenus: 다단계 메뉴
  pureMenu1: 메뉴1
  pureMenu1-1: 메뉴1-1
  pureMenu1-2: 메뉴1-2
  pureMenu1-2-1: 메뉴1-2-1
  pureMenu1-2-2: 메뉴1-2-2
  pureMenu1-3: 메뉴1-3
  pureMenu2: 메뉴2
  purePermission: 권한 관리
  purePermissionPage: 페이지 권한
  purePermissionButton: 버튼 권한
  purePermissionButtonRouter: 경로 복귀 버튼 권한
  purePermissionButtonLogin: 로그인 인터페이스 반환 버튼 권한
  pureTabs: 탭 조작
  pureGuide: 가이드 페이지
  pureAble: 기능
  pureMenuTree: 메뉴 트리 구조
  pureVideoFrame: 비디오 프레임 캡처 - wasm 버전
  pureWavesurfer: 오디오 시각화
  pureRipple: 리플 (Ripple)
  pureMqtt: MQTT 클라이언트 (mqtt)
  pureOptimize: 디바운스, 쓰로틀링, 복사, 롱프레스 지시
  pureVerify: 그래픽 캡차
  pureWatermark: 워터마크
  purePrint: 인쇄
  pureDownload: 다운로드
  pureExternalPage: 외부 페이지
  pureExternalDoc: 문서 외부 링크
  pureEmbeddedDoc: 문서 내장
  pureExternalLink: vue-pure-admin
  pureUtilsLink: pure-admin-utils
  pureColorHuntDoc: 색상 팔레트
  pureUiGradients: 그라디언트
  pureEpDoc: element-plus
  pureTailwindcssDoc: tailwindcss
  pureVueDoc: vue3
  pureViteDoc: vite
  purePiniaDoc: pinia
  pureRouterDoc: vue-router
  pureAbout: 정보
  pureResult: 결과 페이지
  pureSuccess: 성공 페이지
  pureFail: 실패 페이지
  pureIconSelect: 아이콘 선택기
  pureTimeline: 타임라인
  pureLineTree: 트리형 연결 선
  pureList: 리스트 페이지
  pureCardList: 카드 리스트 페이지
  pureDebounce: 디바운스 & 쓰로틀링
  pureFormDesign: 양식 디자이너
  pureBarcode: 바코드
  pureQrcode: QR 코드
  pureCascader: 지역 캐스케이더 선택기
  pureSwiper: Swiper 플러그인
  pureVirtualList: 가상 리스트
  purePdf: PDF 미리보기
  pureExcel: 엑셀 내보내기
  pureInfiniteScroll: 무한 스크롤 테이블
  pureSensitive: 민감한 단어 필터링
  purePinyin: 한어병음
  pureDanmaku: 단막
  pureSchemaForm: 폼
  pureTableBase: 기본 사용법
  pureTableHigh: 고급 사용법
  pureTableEdit: 편집 가능한 사용법
  pureVxeTable: 가상 스크롤
  pureBoard: 아트 보드
  pureMindMap: 마인드 맵
  pureMenuOverflow: 메뉴 오버 플로우 툴팁 표시
  pureChildMenuOverflow: 하위 메뉴 오버 플로우 툴팁 표시
status:
    pureLoad: 로딩 중...
    pureMessage: 메시지
    pureNotify: 통지
    pureTodo: 할 일
    pureNoMessage: 메시지 없음
    pureNoNotify: 통지 없음
    pureNoTodo: 할 일 없음
login:
  pureTenant: 테넌트 이름
  pureUsername: 계정
  purePassword: 비밀번호
  pureVerifyCode: 인증 코드
  pureRemember: 일 내 무로그인
  pureRememberInfo: 선택 후 로그인하면, 지정된 일수 동안 사용자 이름과 비밀번호 없이 시스템에 자동 로그인
  pureSure: 비밀번호 확인
  pureForget: 비밀번호를 잊으셨나요?
  pureLogin: 로그인
  pureThirdLogin: 제3자 로그인
  purePhoneLogin: 휴대폰 로그인
  pureQRCodeLogin: QR 코드 로그인
  pureRegister: 등록
  pureWeChatLogin: 위챗 로그인
  pureAlipayLogin: 알리페이 로그인
  pureQQLogin: QQ 로그인
  pureWeiBoLogin: 웨이보 로그인
  purePhone: 전화번호
  pureSmsVerifyCode: 문자 인증 코드
  pureBack: 돌아가기
  pureTest: 시뮬레이션 테스트
  pureTip: QR 코드를 스캔한 후 "확인"을 클릭하면 로그인 완료
  pureDefinite: 확인
  pureLoginSuccess: 로그인 성공
  pureLoginFail: 로그인 실패
  pureRegisterSuccess: 등록 성공
  pureTickPrivacy: 개인정보 처리방침을 확인해 주세요
  pureReadAccept: 개인정보 처리방침을 주의 깊게 읽고 동의합니다
  purePrivacyPolicy: 《개인정보 처리방침》
  pureGetVerifyCode: 인증 코드 받기
  pureInfo: 초 후에 다시 받기
  pureTenantReg: 테넌트 이름을 입력하세요
  pureUsernameReg: 계정을 입력하세요
  purePassWordReg: 비밀번호를 입력하세요
  pureVerifyCodeReg: 인증 코드를 입력하세요
  pureVerifyCodeCorrectReg: 올바른 인증 코드를 입력하세요
  pureVerifyCodeSixReg: 6자리 숫자 인증 코드를 입력하세요
  purePhoneReg: 휴대폰 번호를 입력하세요
  purePhoneCorrectReg: 올바른 휴대폰 번호 형식을 입력하세요
  purePassWordRuleReg: 비밀번호는 숫자, 문자, 기호 중 두 종류를 조합한 8-18자로 설정해야 합니다
  purePassWordSureReg: 비밀번호를 다시 입력하세요
  purePassWordDifferentReg: 두 비밀번호가 일치하지 않습니다!
  purePassWordUpdateReg: 비밀번호 변경 성공